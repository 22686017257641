import { FC } from 'react';
import { Box, SxProps } from '@mui/material';
import { LoadingIceCream as LoadingAnimation } from './icons/LoadingIceCream';


export type LoadingIceCreamProps = {
  message?: string,
  style?: React.CSSProperties,
  sx?: SxProps,
}


export const LoadingIceCream: FC<LoadingIceCreamProps> = function LoadingIceCream({ message='', style={}, sx={}, ...props}) {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        position: "relative",
        top: "-40px",
        flexDirection: "column",
        typography: "body1",
        color: 'text.secondary',
        gap: 1,
      }}
      data-cy='loading-icecream'
    >
        <LoadingAnimation style={{ width: 50, height: 75, color: 'rgba(238, 66, 102, 1)', ...style }} sx={sx} {...props} />
        {message}
    </Box>
  );
}
