import { FC } from "react"
import { Helmet as RealHelmet } from "react-helmet-async"
import { useLocation } from "react-router";
import { useTheme } from "@mui/material";

import { createWebSiteType } from "../utils/json-ld";
import { useSession } from "@/hooks/auth";


export const HelmetReset: FC = function HelmetReset() {

  const theme = useTheme();
  const location = useLocation();
  const { user } = useSession();


  const host = "https://app.icecream.club"
  const currentUrl = host + location.pathname;

  const searchParams = new URLSearchParams(location.search);
  // add token if user is logged in
  if(user?.token) {
    searchParams.set('token', user.token);
  }
  const appBannerUrl = `${currentUrl}?${searchParams}`;

  const jsonLd = createWebSiteType();

  return (
    <RealHelmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no" />
      <meta name="HandheldFriendly" content="true" />
      <meta name="theme-color" content="#FFFFFF" />

      <title>Ice Cream Club</title>
      <meta name="description" content="Waste your time better – Share and discover movies, TV-series, books, and more with your friends." />
      <meta name="keywords" content="movies, tv-series, books, music, games, podcasts, ice cream club, share, discover, friends" />
      <link rel="canonical" href={currentUrl} />
      <meta name="author" content="" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="" />
      <meta name="twitter:site" content="@IceCreamClubHQ" />
      <meta property="og:title" content="Ice Cream Club" />
      <meta property="og:description" content="Waste your time better – Share and discover movies, TV-series, books, and more with your friends." />
      <meta property="og:image" content="https://app.icecream.club/kawaii.png" />

      <script type="application/ld+json">{jsonLd && JSON.stringify(jsonLd)}</script>


      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <meta name="apple-itunes-app" content={`app-id=1565743981, app-argument=${appBannerUrl}`} />

      <link rel="icon" type="image/png" sizes="1024x1024" href="/favicon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="512x512" href="/android-chrome-512x512.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />

      <style type="text/css">{`
        * {
          touch-action: pan-x pan-y; /* disable zoom in iOS */
        }

        :root {
          --brand: rgba(238, 66, 102, 1);
          --brand-light: rgba(247, 161, 179, 1);
          accent-color: var(--brand);
          --sat: env(safe-area-inset-top);
          --sar: env(safe-area-inset-right);
          --sab: env(safe-area-inset-bottom);
          --sal: env(safe-area-inset-left);
          --status-bar-color: ${theme.palette.background.paper};
        }
        :root:has(.fullscreen-video) {
          --sat: 0;
          --sar: 0;
          --sab: 0;
          --sal: 0;
          --status-bar-color: black;
        }
        html {
          /* scrollbar-color: var(--brand-light) Canvas; */
           scrollbar-width: none;
        }

        :focus-visible { outline-color: var(--brand); }
        ::selection { background-color: var(--brand-light); }
        ::marker { color: var(--brand); }

        :is(
          ::-webkit-calendar-picker-indicator,
          ::-webkit-clear-button,
          ::-webkit-inner-spin-button,
          ::-webkit-outer-spin-button
        ) {
          color: var(--brand);
        }

        html, body, #root {
          /* fix for preventing scroll in ios */
          height: 100%;
          width: 100%;
          overflow: hidden;
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0;
        }

        *::-webkit-scrollbar {
          display: none;
        }

        body {
          /* margin for top notch on ios */
          padding-top: constant(safe-area-inset-top);
          padding-top: var(--sat);
          transition: background-color 30ms linear;
        }

        body.ios-status-bar:before {
          content: "";
          position: fixed;
          z-index: 10000;
          top: 0;
          height: var(--sat);
          right: 0;
          left: 0;
          background-color: var(--status-bar-color);
          transition: background-color 30ms linear;
        }
        body.ios-status-bar--white:before {
          background-color: #fff;
        }
        body.ios-status-bar--black:before {
          background-color: #000;
        }
        body.ios-status-bar--300:before {
          background-color: #e0e0e0;
        }

        .SnackbarContainer-top {
          top: calc(var(--sat) + 16px) !important;
        }
        .SnackbarContainer-right {
          top: calc(var(--sar) + 16px) !important;
        }
        .SnackbarContainer-bottom {
          top: calc(var(--sab) + 16px) !important;
        }
        .SnackbarContainer-left {
          top: calc(var(--sal) + 16px) !important;
        }

        a {
          text-decoration: none;
          color: unset;
        }

        img, a {
          user-select: none;
          -webkit-user-drag: none;
        }

        .carousel__slide-focus-ring {
          display: none;
        }

      `}</style>
    </RealHelmet>
  );
}
