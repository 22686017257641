import { createContext, FC, ReactNode, RefObject, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import type ReactPlayer from "react-player";
import { debounce } from "lodash";

import { useIsVisible } from "./use-is-visible";
import { isServer } from "@/utils/detect";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useOrientation } from "./orientation";


export type VideoPlayerContextType = {
  players: PlayerState[],
  scrollObserver: IntersectionObserver | null,
  addPlayer: (player: PlayerState) => void,
  removePlayer: (player: PlayerState) => void,
  setActivated: (player: PlayerState | null, isActivated: boolean) => void,
  setPlaying: (player: PlayerState | null, isPlaying: boolean) => void,
  setFullscreen: (player: PlayerState | null, value: boolean) => void,
  fullscreenOrientation: 'landscape-left' | 'landscape-right' | null,
  debouncedUpdatePlaying: () => void,
};


export type PlayerState = {
  playerRef: RefObject<ReactPlayer>,
  containerRef: RefObject<HTMLDivElement>,
  isActivated: boolean,
  isPlaying: boolean,
  isFullscreen: boolean,
  isVisible: boolean,
  intersectionRatio: number,
  isIntersecting: boolean,
  boundingClientRect?: DOMRect,
};


const VideoPlayerContext = createContext<VideoPlayerContextType>({
  players: [],
  scrollObserver: null,
  addPlayer: () => {},
  removePlayer: () => {},
  setActivated: () => {},
  setPlaying: () => {},
  setFullscreen: () => {},
  fullscreenOrientation: null,
  debouncedUpdatePlaying: () => {},
});


export const VideoPlayerProvider: FC<{ children: ReactNode }> = function VideoPlayerProvider({ children }) {

  const [orientation, requestPermission] = useOrientation();

  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [players] = useState<PlayerState[]>([]);
  const [fullscreenOrientation, setFullscreenOrientation] = useState<'landscape-left' | 'landscape-right' | null>(null);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);


  const setPlaying = useCallback((player: PlayerState | null, isPlaying: boolean) => {
    let changed = false;
    for(const p of players) {
      if(p === player) {
        if(p.isPlaying !== isPlaying) {
          // console.log('VideoPlayerProvider setPlaying', p, isPlaying);
          changed = true;
        }
        p.isPlaying = isPlaying;
      } else {
        if(p.isPlaying !== false) {
          changed = true;
        }
        p.isPlaying = false;
      }
    }

    if(changed) {
      setForceUpdate(forceUpdate => !forceUpdate);
    }
  }, [players]);


  const setActivated = useCallback((player: PlayerState | null, isActivated: boolean) => {
    requestPermission();

    let changed = false;
    for(const p of players) {
      if(p === player) {
        if(p.isActivated !== isActivated) {
          changed = true;
        }
        p.isActivated = isActivated;
      } else {
        if(p.isActivated !== false) {
          changed = true;
        }
        p.isActivated = false;
      }
    }
    if(changed) {
      setForceUpdate(forceUpdate => !forceUpdate);
    }
  }, [players, requestPermission]);


  const observePlayers = useCallback((players: PlayerState[], intersectionObserver: IntersectionObserver | null) => {
    for(const player of players) {
      // Play/pause video when element is entering/exiting viewport using IntersectionObserver
      const container = player?.containerRef?.current;
      if(!container) {
        // console.error('Could not find container element', player);
      } else if(!intersectionObserver) {
        console.error('VideoPlayerProvider observePlayers intersectionObserver not available');
      } else {
        // console.log('VideoPlayerProvider observePlayers observe', player);
        intersectionObserver?.observe(container);
      }
    }
  }, []);


  const updatePlaying = useCallback(() => {
    players.forEach(player => {
      player.boundingClientRect = player.isIntersecting ? player.containerRef.current?.getBoundingClientRect() : undefined;
    });

    const visiblePlayers = players.filter(p => p.intersectionRatio >= 0.95 && p.boundingClientRect?.top)
    const topPlayer = visiblePlayers.sort((a, b) => a.boundingClientRect!.top - b.boundingClientRect!.top)[0];
    const activatedPlayer = visiblePlayers.find(p => p.isActivated);
    const fullscreenPlayer = players.find(p => p.isFullscreen);
    // console.log('VideoPlayerProvider updatePlaying', players.map(p => ({ isPlaying: p.isPlaying, isActivated: p.isActivated, isFullscreen: p.isFullscreen, intersectionRatio: p.intersectionRatio, isIntersecting: p.isIntersecting })), { topPlayer, activatedPlayer, fullscreenPlayer });
    if(activatedPlayer || fullscreenPlayer) {
      // do nothing if user has activated a player that is still visible
    } else {
      if(topPlayer) {
        setPlaying(topPlayer, true);
      } else {
        setPlaying(null, false);
        setActivated(null, false);
      }
    }
  }, [players, setPlaying, setActivated]);


  const debouncedUpdatePlaying = useMemo(() => debounce(updatePlaying, 300, { leading: false, trailing: true, maxWait: 500 }), [updatePlaying]);


  useEffect(() => {
    if(isServer) {
      return;
    }
    // console.log('VideoPlayerProvider useEffect create IntersectionObserver');
    const observer = new IntersectionObserver((entries) => {
      // console.log('VideoPlayerProvider IntersectionObserver', entries.map(e => e.intersectionRatio), entries, players);
      entries.forEach(entry => {
        const player = players.find(p => p.containerRef.current === entry.target);
        if(player) {
          player.intersectionRatio = entry.intersectionRatio;
          player.isIntersecting = entry.isIntersecting;
        }
      });

      debouncedUpdatePlaying();
    }, {
      threshold: [0, 0.05, 0.5, 0.95, 1],
    });
    intersectionObserverRef.current = observer;

    observePlayers(players, observer);

    return () => {
      // console.log('VideoPlayerProvider useEffect cleanup IntersectionObserver');
      observer.disconnect();
    };
  }, [players, debouncedUpdatePlaying, observePlayers]);


  const addPlayer = useCallback((player: PlayerState) => {
    if(!intersectionObserverRef.current) {
      console.warn('VideoPlayerProvider addPlayer intersectionObserver not available');
      return;
    }
    players.push(player);
    observePlayers(players, intersectionObserverRef.current);
    debouncedUpdatePlaying();
  }, [players, debouncedUpdatePlaying, observePlayers]);


  const removePlayer = useCallback((player: PlayerState) => {
    if(player.containerRef.current) {
      // console.log('useVideoPlayer useEffect unobserve', player.containerRef.current);
      intersectionObserverRef.current?.unobserve(player.containerRef.current);
    }
    players.splice(players.indexOf(player), 1)
  }, [players]);


  const setFullscreen = useCallback((player: PlayerState | null, value: boolean) => {
    if(value === player?.isFullscreen) {
      return;
    }
    let changed = false;
    for(const p of players) {
      if(p === player) {
        if(p.isFullscreen !== value) {
          p.isFullscreen = value;
          changed = true;
        }
      } else {
        if(p.isFullscreen !== false) {
          p.isFullscreen = false;
          changed = true;
        }
      }
    }

    if(changed) {
      if(value) {
        document.body.classList.add('fullscreen-video');
        if(Capacitor.isNativePlatform()) {
          StatusBar.hide().catch(e => console.error('StatusBar.hide error', e));
          StatusBar.setStyle({ style: Style.Dark }).catch(e => console.error('StatusBar.setStyle error', e));
          if(Capacitor.getPlatform() === 'android') {
            // StatusBar.setBackgroundColor({ color: 'black' }).catch(e => console.error('StatusBar.setBackgroundColor error', e));
            StatusBar.setOverlaysWebView({ overlay: true }).catch(e => console.error('StatusBar.setOverlaysWebView error', e));
          }
        }
      } else {
        document.body.classList.remove('fullscreen-video');
        if(Capacitor.isNativePlatform()) {
          StatusBar.setStyle({ style: Style.Default }).catch(e => console.error('StatusBar.setStyle error', e));
          StatusBar.show().catch(e => console.error('StatusBar.show error', e));
          if(Capacitor.getPlatform() === 'android') {
            // StatusBar.setBackgroundColor({ color: 'env(--status-bar-color)' }).catch(e => console.error('StatusBar.setBackgroundColor error', e));
            StatusBar.setOverlaysWebView({ overlay: false }).catch(e => console.error('StatusBar.setOverlaysWebView error', e));
          }
        }
      }
      setFullscreenOrientation(orientation?.calculated === 'landscape-left' ? 'landscape-left' : 'landscape-right');
      setForceUpdate(!forceUpdate);
    }
  }, [players, forceUpdate, orientation]);


  useEffect(() => {
    const activatedPlayer = players.find(p => p.isActivated);
    if(!orientation) {
      // orientation not available, do nothing
    } else if((orientation.calculated === 'landscape-left' || orientation.calculated === 'landscape-right') && activatedPlayer && !activatedPlayer.isFullscreen) {
      setFullscreen(activatedPlayer, true);
    } else if(orientation.calculated === 'portrait' && activatedPlayer?.isFullscreen) {
      setFullscreen(null, false);
    }
  }, [orientation, players, setFullscreen]);

  const value = useMemo(() => ({
    players,
    scrollObserver: intersectionObserverRef.current,
    addPlayer,
    removePlayer,
    setActivated,
    setPlaying,
    setFullscreen,
    fullscreenOrientation,
    debouncedUpdatePlaying,
  }), [players, addPlayer, removePlayer, setActivated, setPlaying, setFullscreen, fullscreenOrientation, debouncedUpdatePlaying]);

  return (
    <VideoPlayerContext.Provider value={value}>
      {children}
    </VideoPlayerContext.Provider>
  );
};


export const useVideoPlayer = (playerRef: RefObject<ReactPlayer>, containerRef: RefObject<HTMLDivElement>, onClick?: (ev: SyntheticEvent) => void) => {

  const history = useHistory();
  const isVisible = useIsVisible();

  const context = useContext(VideoPlayerContext);

  const wasActivated = useRef<boolean>(false);
  const [activationAnimationState, setActivationAnimationState] = useState<'initial' | 'running' | 'completed'>('initial');
  const activationAnimationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);


  useEffect(() => {
    if(!context.players.some(p => p.playerRef === playerRef)) {
      const player = {
        playerRef,
        containerRef,
        isActivated: false,
        isPlaying: false,
        isFullscreen: false,
        isVisible,
        intersectionRatio: 0,
        isIntersecting: false,
        boundingClientRect: undefined,
      };
      // console.log('useVideoPlayer useEffect addPlayer', playerRef, player, context.players);
      context.addPlayer(player);

      return () => {
        // only remove if unavailable after a timeout, to prevent remount loop
        setTimeout(() => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          if(!containerRef.current?.parentElement) { // check that it has been removed from the DOM
            context.removePlayer(player);
          }
        }, 1000);
      };
    } else {
      // console.log('useVideoPlayer useEffect player already exists', playerRef, context.players);
    }
  }, [playerRef, containerRef, context, isVisible]);


  const handleClick = useCallback((ev: SyntheticEvent) => {
    if(onClick) {
      onClick(ev);
      return;
    }

    const player = context.players.find(p => p.playerRef === playerRef);
    const playerEl = playerRef.current;
    const internalPlayer = playerEl?.getInternalPlayer();

    if(player && internalPlayer) {
      context.setActivated(player, true);
      context.setPlaying(player, true);

      internalPlayer.setPlaybackQuality('auto');
      playerEl?.seekTo(0);

      // console.log('video-player handleClick', { wasActivated: wasActivated.current, isActivated: player?.isActivated, activationAnimationState });
      if(activationAnimationState === 'initial') {
        setActivationAnimationState('running');
        activationAnimationTimeoutRef.current = setTimeout(() => {
          // don't change if it was reset while timeout was running
          setActivationAnimationState((prev) => prev === 'running' ? 'completed' : prev);
        }, 850);
      }
    } else {
      console.error(`Could not find internal player`, { player, playerEl, internalPlayer });
    }
  }, [playerRef, onClick, context, activationAnimationState]);


  useEffect(() => {
    const unlisten = history.listen(() => {
      // console.log('Reset player on navigation');
      context.setPlaying(null, false);
      context.setActivated(null, false);
      setActivationAnimationState('initial');
      wasActivated.current = false;
      if(activationAnimationTimeoutRef.current) {
        clearTimeout(activationAnimationTimeoutRef.current);
        activationAnimationTimeoutRef.current = null;
      }
      context.debouncedUpdatePlaying();
    });

    return unlisten;
  }, [history, context, playerRef]);


  useEffect(() => {
    const player = context.players.find(p => p.playerRef === playerRef);

    if(player?.isVisible && !isVisible && player.isPlaying) {
      // console.log('Reset player when not visible');
      context.setPlaying(player, false);
      context.setActivated(player, false);
      setActivationAnimationState('initial');
      wasActivated.current = false;
      if(activationAnimationTimeoutRef.current) {
        clearTimeout(activationAnimationTimeoutRef.current);
        activationAnimationTimeoutRef.current = null;
      }
      context.debouncedUpdatePlaying();
    }
  }, [context, playerRef, isVisible]);


  useEffect(() => {
    return () => {
      if(activationAnimationTimeoutRef.current) {
        clearTimeout(activationAnimationTimeoutRef.current);
        activationAnimationTimeoutRef.current = null;
      }
    };
  }, []);


  return {
    context,
    handleClick,
    player: context.players.find(p => p.playerRef === playerRef),
    setFullscreen: (value: boolean) => context.setFullscreen(context.players.find(p => p.playerRef === playerRef) || null, value),
    fullscreenOrientation: context.fullscreenOrientation,
    setActivated: (isActivated: boolean) => context.setActivated(context.players.find(p => p.playerRef === playerRef) || null, isActivated),
    activationAnimationState,
  };
}
