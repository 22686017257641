// global window
export const isServer = typeof window === 'undefined';

export function localStorage () {
  const test = '__test__';
  try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
  } catch(e) {
      return false;
  }
}

export const localStorageAvailable = localStorage();

export const iOS = !isServer && (
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(window.navigator.platform)
  ||
  // iPad on iOS 13 detection
  (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));

export const isMac = !isServer && /(Mac)/i.test(window.navigator.platform);

export const isMacLike = isMac || iOS;

export const isAndroid = !isServer && /(Android)/i.test(window.navigator.userAgent);

export const isHydrating = () => !isServer && window.IS_HYDRATING;
